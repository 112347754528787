import React, { FC, ElementType, ReactNode, Ref, forwardRef } from "react";
import styled from "styled-components";
import { Level, levelMap } from "./HeadingLevels";
import {
  generateResponsiveProps,
  CustomInterpolation,
  ThemeObject,
  FontSizeProp,
  SpacingProp,
  NonThemeProp,
} from "../../utils/responsive-props";
import {
  BaseTextProps,
  TextTransientProps,
  MarginTransientProps,
  WidthTransientProps,
  FontKeys,
} from "../../system";

export interface HeadingProps extends BaseTextProps {
  as?: ElementType;
  children: ReactNode;
  level?: Level;
  marginBottom?: SpacingProp;
  ref?: Ref<HTMLHeadingElement>;
}

export type BaseHeadingProps = HeadingProps &
  TextTransientProps &
  WidthTransientProps &
  Pick<MarginTransientProps, "$marginBottom">;

export const BaseHeading = styled.div<BaseHeadingProps>`
  color: ${({ textColor = "black", theme: { colors } }): string =>
    colors[textColor]};

  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "1")};
  font-family: ${({ $fontFamily = "futuraHeavy", theme: { fonts } }): string =>
    fonts[$fontFamily as FontKeys]};
  letter-spacing: ${({ tracking = "normal" }): string => tracking};
  text-transform: ${({ $textTransform = "none" }): string => $textTransform};
  -webkit-font-smoothing: ${({ $fontFamily }) =>
    $fontFamily === "parnaso" ? "auto" : ""};
  ${({ textSize, level, theme: { fontSizes } }): CustomInterpolation =>
    generateResponsiveProps<FontSizeProp>({
      cssProperty: "font-size",
      prop: level ? levelMap[level] : textSize ?? "f11",
      themeObject: fontSizes as ThemeObject,
    })};

  ${({ alignment = "left" }): CustomInterpolation =>
    generateResponsiveProps<NonThemeProp>({
      cssProperty: "text-align",
      prop: alignment,
    })};

  margin-top: 0;

  ${({ $marginBottom = "sp0", theme: { spacing } }): CustomInterpolation =>
    generateResponsiveProps<SpacingProp>({
      cssProperty: "margin-bottom",
      prop: $marginBottom,
      themeObject: spacing as ThemeObject,
    })};

  ${({ $width = "100%" }): CustomInterpolation =>
    generateResponsiveProps<NonThemeProp>({
      cssProperty: "width",
      prop: $width,
    })};

  ${({ $maxWidth = "100%" }): CustomInterpolation =>
    generateResponsiveProps<NonThemeProp>({
      cssProperty: "max-width",
      prop: $maxWidth,
    })};
`;

const Heading: FC<HeadingProps> = forwardRef(
  (
    {
      alignment = "left",
      as = "h1",
      children,
      className,
      dataId,
      fontFamily = "parnaso",
      id,
      level,
      marginBottom = "sp0",
      maxWidth = "none",
      textColor = "navy",
      textSize,
      textTransform = "none",
      tracking = "normal",
      width = "auto",
      lineHeight,
    },
    ref
  ) => {
    return (
      <BaseHeading
        $fontFamily={fontFamily}
        $marginBottom={marginBottom}
        $maxWidth={maxWidth}
        $textTransform={textTransform}
        $width={width}
        alignment={alignment}
        as={as}
        className={className}
        data-id={dataId}
        id={id}
        level={level}
        textColor={textColor}
        textSize={textSize}
        tracking={tracking}
        lineHeight={lineHeight}
        ref={ref}
      >
        {children}
      </BaseHeading>
    );
  }
);

export default Heading;
