import React, { FC, ElementType, ReactNode } from "react";
import styled from "styled-components";
import {
  CustomInterpolation,
  generateResponsiveProps,
  FontSizeProp,
  ThemeObject,
  NonThemeProp,
  SpacingProp,
} from "../../utils/responsive-props";
import {
  BaseTextProps,
  MarginTransientProps,
  TextTransientProps,
  WidthTransientProps,
} from "../../system";

type Weight = "bold" | "normal" | "variant" | "italic";

export interface BodyTextProps extends BaseTextProps {
  as?: ElementType;
  children: ReactNode;
  marginBottom?: SpacingProp;
  weight?: Weight;
  style?: Record<any, string>;
}

export type BaseBodyTextProps = BodyTextProps &
  WidthTransientProps &
  Pick<MarginTransientProps, "$marginBottom"> &
  Pick<TextTransientProps, "$fontFamily">;

const BodyTextBase = styled.p<BaseBodyTextProps>`
  color: ${({ textColor = "black", theme: { colors } }): string =>
    colors[textColor]};

  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "1.5")};
  font-family: ${({
    weight = "normal",
    $fontFamily = "helvetica",
    theme: { fonts },
  }): string => {
    return fonts[$fontFamily];
  }};
  font-weight: ${({ weight }) => (weight ? weight : "normal")};
  letter-spacing: ${({ tracking = "normal" }): string => tracking};
  text-transform: ${({ textTransform = "none" }): string => textTransform};
  -webkit-font-smoothing: ${({ $fontFamily }) =>
    $fontFamily === "parnaso" ? "auto" : ""};

  ${({ textSize = "f4", theme: { fontSizes } }): CustomInterpolation =>
    generateResponsiveProps<FontSizeProp>({
      cssProperty: "font-size",
      prop: textSize,
      themeObject: fontSizes as ThemeObject,
    })};

  ${({ alignment = "left" }): CustomInterpolation =>
    generateResponsiveProps<NonThemeProp>({
      cssProperty: "text-align",
      prop: alignment,
    })};

  ${({ $marginBottom = "sp0", theme: { spacing } }): CustomInterpolation =>
    generateResponsiveProps<SpacingProp>({
      cssProperty: "margin-bottom",
      prop: $marginBottom,
      themeObject: spacing as ThemeObject,
    })};

  ${({ $width = "100%" }): CustomInterpolation =>
    generateResponsiveProps<NonThemeProp>({
      cssProperty: "width",
      prop: $width,
    })};

  ${({ $maxWidth = "100%" }): CustomInterpolation =>
    generateResponsiveProps<NonThemeProp>({
      cssProperty: "max-width",
      prop: $maxWidth,
    })};

  a {
    color: ${({ theme }) => theme.colors.navy};
    text-decoration: underline;
  }
`;

const BodyText: FC<BodyTextProps> = ({
  alignment = "left",
  as = "p",
  children,
  className,
  dataId,
  fontFamily = "helvetica",
  id,
  marginBottom = "sp0",
  textSize = "f3",
  tracking = "normal",
  weight = "normal",
  maxWidth = "none",
  textColor = "navy",
  textTransform = "none",
  width = "auto",
  lineHeight = "1.5",
  style,
}) => (
  <BodyTextBase
    $fontFamily={fontFamily}
    $marginBottom={marginBottom}
    $maxWidth={maxWidth}
    $width={width}
    alignment={alignment}
    as={as}
    className={className}
    data-id={dataId}
    id={id}
    textColor={textColor}
    textSize={textSize}
    textTransform={textTransform}
    tracking={tracking}
    weight={weight}
    lineHeight={lineHeight}
    style={style}
  >
    {children}
  </BodyTextBase>
);

export default BodyText;
